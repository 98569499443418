import styled from "styled-components";

export const Hand = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  z-index: 55;
  position: absolute;
  top: -42px;
`;
