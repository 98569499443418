// reducers/tablesReducer.js
const initialState = {
  loading: false,
  tables: [],
  error: ''
};

const tablesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_TABLES_REQUEST':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_TABLES_SUCCESS':
      return {
        loading: false,
        tables: action.payload,
        error: ''
      };
    case 'FETCH_TABLES_FAILURE':
      return {
        loading: false,
        tables: [],
        error: action.payload
      };
    default:
      return state;
  }
};

export default tablesReducer;
