import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CardLayout from "../../assets/img/card-layout.png";
import InnerCardLogoBear from "../../assets/img/inner-card-logo-bear.png";
import InnerCardLogoOx from "../../assets/img/inner-card-logo-ox.png";
import globalContext from "../../context/global/globalContext";
import CardBackside from "../../assets/img/backside-card-cropped.png";

const UserCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ProfileCard = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: black;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  border: 4px solid
    ${(props) => (props.borderColor ? props.borderColor : "lightblue")};
`;

const NameStatus = styled.div`
  display: flex;
  flex-direction: column;
  background: #2c2c2c;
  border-radius: 12px;
  padding: 10px;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const Status = styled.span`
  font-size: 16px;
  color: #00ff00;
`;

const CardList = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;

const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 125px;
  height: 180px;
  background-image: url(${CardLayout});
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
`;

const InnerLogo = styled.img`
  position: absolute;
  width: 100px;
  height: 100px;
  top: ${(props) => (props.src === InnerCardLogoBear ? "55%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${(props) => (props.src === InnerCardLogoBear ? "125px" : "100px")};
`;

const MetricLabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 5px;
`;

const MetricLabelContainerUpSideDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5px;
  transform: rotate(180deg);
`;

const MetricLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-inline: 10px;
`;

const MetricValue = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.up ? "green" : "red")};
  position: absolute;
  bottom: 20px;
`;

const BacksideCardContainer = styled.div`
  display: "block";
  width: 100%;
  padding: 0;
`;

const WaitingForHandContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Ensure it takes full height of the parent
  background-color: #2c2c2c; // Optional: Add a background color
  border-radius: 12px; // Optional: Add border radius
  text-align: center; // Center text within the container
`;

const WaitingForHandHeading = styled.h2`
  font-size: 24px; // Adjust font size as needed
  font-weight: bold;
  color: white; // Change color as needed
  margin: 0; // Remove default margin to help with centering
`;

const UserProfileCards = ({ currentTable }) => {
  const [data, setData] = useState([]);
  const { id } = useContext(globalContext);

  useEffect(() => {
    setData([]);
    const data = getSidebarFormattedData(currentTable?.seats || [], id);
    setData(data);
  }, [currentTable, id]);

  function getSidebarFormattedData(seats, userId) {
    const seatData = Object.values(seats).filter(
      (seat) => seat?.player?.id !== userId
    );
    if (seatData.length === 0) return null;

    const formattedData = seatData.map((seat) => ({
      id: seat?.player?.id || null,
      name: seat?.player?.name || null,
      profilePic: seat?.player?.profilePic || null,
      status: seat?.player?.bankroll || null,
      cards: seat?.hand || [],
    }));

    return formattedData.filter((item) => item?.id !== null);
  }

  return (
    <UserCardList>
      {data?.length > 0 ? (
        <>
          {data.map((user) => (
            <>
              {user?.cards?.length > 0 ? (
                <ProfileCard key={user.id}>
                  <CardList>
                    {user.cards.map((card, index) => (
                      <React.Fragment key={index}>
                        {card.display ? (
                          <MetricCard
                            key={index}
                            up={Math.sign(card?.difference) === 1}
                          >
                            <InnerLogo
                              src={
                                Math.sign(card?.difference) === 1
                                  ? InnerCardLogoOx
                                  : InnerCardLogoBear
                              }
                            />
                            <MetricLabelContainer>
                              <MetricLabel>{card.symbol}</MetricLabel>
                              <MetricLabel>{card.rank}</MetricLabel>
                            </MetricLabelContainer>

                            <MetricLabelContainerUpSideDown>
                              <MetricLabel>{card.symbol}</MetricLabel>
                              <MetricLabel>{card.rank}</MetricLabel>
                            </MetricLabelContainerUpSideDown>

                            <MetricValue up={Math.sign(card?.difference) === 1}>
                              {card?.difference}
                            </MetricValue>
                          </MetricCard>
                        ) : (
                          <BacksideCardContainer>
                            <img
                              style={{ height: "180px", width: "100%" }}
                              src={CardBackside}
                              alt="Backside-of-card"
                            />
                          </BacksideCardContainer>
                        )}
                      </React.Fragment>
                    ))}
                  </CardList>
                  <ProfileInfo>
                    <Avatar
                      src={user.profilePic}
                      borderColor={user.statusColor}
                    />
                    <NameStatus>
                      <Name>{user.name}</Name>
                      <Status>{user.status}</Status>
                    </NameStatus>
                  </ProfileInfo>
                </ProfileCard>
              ) : (
                <WaitingForHandContainer>
                  <WaitingForHandHeading>
                    Waiting for hand to start.
                  </WaitingForHandHeading>
                </WaitingForHandContainer>
              )}
            </>
          ))}
        </>
      ) : (
        <WaitingForHandContainer>
          <WaitingForHandHeading>
            Waiting for game to start.
          </WaitingForHandHeading>
        </WaitingForHandContainer>
      )}
    </UserCardList>
  );
};

export default UserProfileCards;
