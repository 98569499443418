import React from "react";
import styled from "styled-components";
import CloseButton from "../buttons/CloseButton";
import UserProfileCards from "./UserProfileCards";

const NavMenuWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
`;

const StyledNavMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 50vw;
  height: 100%;
  background-color: ${(props) => props.theme.colors.lightestBg};
  box-shadow: ${(props) => props.theme.other.navMenuDropShadow};
  overflow: hidden;

  @media screen and (max-width: 400px) {
    width: 85vw;
  }
`;

const MenuBody = styled.div`
  margin-top: 50px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

const GameSidebar = ({ onClose, currentTable }) => {
  return (
    <NavMenuWrapper
      id="wrapper"
      onClick={(e) => {
        if (e.target.id === "wrapper") {
          onClose();
        }
      }}
    >
      <StyledNavMenu>
        <IconWrapper>
          <CloseButton clickHandler={onClose} autoFocus />
        </IconWrapper>
        <MenuBody>
          <UserProfileCards currentTable={currentTable} />
        </MenuBody>
      </StyledNavMenu>
    </NavMenuWrapper>
  );
};

export default GameSidebar;
