import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CardLayout from "../../assets/img/card-layout.png";
import InnerCardLogoBear from "../../assets/img/inner-card-logo-bear.png";
import InnerCardLogoOx from "../../assets/img/inner-card-logo-ox.png";
import globalContext from "../../context/global/globalContext";
import gameContext from "../../context/game/gameContext";
import { SeatTimer } from "./SeatTImer";
import userImages from './userImages';

const UserCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const ProfileCard = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  // background-color: black;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const Avatar = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
  border: 4px solid
    ${(props) => (props.borderColor ? props.borderColor : "lightblue")};
`;

const NameStatus = styled.div`
  display: flex;
  flex-direction: column;
  background: #2c2c2c;
  border-radius: 12px;
  padding: 10px;
`;

const Name = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: white;
`;

const Status = styled.span`
  font-size: 16px;
  color: #00ff00;
`;

const CardList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const Tooltip = styled.div`
  visibility: hidden;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: -15%; /* Position the tooltip above the card */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  white-space: nowrap; /* Ensure text is on a single line */

  &::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 125px;
  height: 180px;
  background-image: url(${CardLayout});
  background-size: cover;
  border-radius: 10px;
  box-shadow: ${(props) => (props.isSelected ? "0px 8px 16px rgba(0, 0, 0, 0.2)" : "0px 4px 6px rgba(0, 0, 0, 0.1)")};
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  cursor: pointer;
  transform: ${(props) => (props.isSelected ? "scale(1.07)" : "scale(1)")};
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover ${Tooltip} {
    visibility: visible;
    opacity: 1;
  }
`;

const InnerLogo = styled.img`
  position: absolute;
  width: 100px;
  top: ${(props) => (props.src === InnerCardLogoBear ? "50%" : "47%")};
  left: 50%;
  transform: translate(-50%, -50%);
  height: ${(props) => (props.src === InnerCardLogoBear ? "100px" : "85px")};
`;

const MetricLabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 5px;
`;

const MetricLabelContainerUpSideDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5px;
  transform: rotate(180deg);
`;

const MetricLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-inline: 10px;
`;

const MetricValue = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: ${(props) => (props.up ? "green" : "red")};
  position: absolute;
  bottom: 30px;
`;

const TimerAvatarContainer = styled.div`
  position: relative;
`;

const TimerContainer = styled.div`
  position: absolute;
  top: -30px;
  left: -30px;
`;

const UserSeatAndCards = ({ currentTable }) => {
  const { id, picture } = useContext(globalContext);
  const [seatData, setSeatData] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null); 
  const { setCard } = useContext(gameContext);

  useEffect(() => {
    setSeatData(null);
    const data = getSeatDataByUserId(currentTable?.seats || [], id);
    setSeatData(data);
  }, [currentTable, id]);

  function getSeatDataByUserId(seats, userId) {
    const seatData = Object.values(seats).find(
      (seat) => seat?.player?.id === userId
    );
    if (!seatData) return null;
    return {
      id: seatData?.player?.id || null,
      name: seatData?.player?.name || null,
      profilePic: picture || userImages[seatData?.id],
      status: seatData.player.bankroll || null,
      cards: seatData?.hand || [],
      turn: seatData?.turn,
    };
  }

  const handleCardSelection = (cardSymbol) => {
    setCard(cardSymbol);
    setSelectedCard(cardSymbol); 
  };

  return (
    <>
    {seatData && seatData?.cards?.length > 0 && (
      <UserCardList>
        <ProfileCard key={seatData.id}>
          <ProfileInfo>
            <TimerAvatarContainer>
              <Avatar
                src={seatData.profilePic}
                borderColor={seatData.statusColor}
              />
              <TimerContainer>
                <SeatTimer hasTurn={seatData.turn} />
              </TimerContainer>
            </TimerAvatarContainer>
            <NameStatus>
              <Name>{seatData.name}</Name>
              <Status>{seatData.status}</Status>
            </NameStatus>
          </ProfileInfo>
          <CardList>
            {seatData.cards.map((card, index) => (
              <MetricCard
                key={index}
                up={Math.sign(card?.difference) === 1}
                isSelected={selectedCard === card.symbol} // Check if the card is selected
                onClick={() => {
                  if (!card.isRevealed) {
                    handleCardSelection(card.symbol);
                  }
                }}
              >
                {card.isRevealed && (
                  <Tooltip>Card is already revealed.</Tooltip>
                )}
                <InnerLogo
                  src={
                    Math.sign(card?.difference) === 1
                      ? InnerCardLogoOx
                      : InnerCardLogoBear
                  }
                />
                <MetricLabelContainer>
                  <MetricLabel>{card.symbol}</MetricLabel>
                  <MetricLabel>{card.rank}</MetricLabel>
                </MetricLabelContainer>

                <MetricLabelContainerUpSideDown>
                  <MetricLabel>{card.symbol}</MetricLabel>
                  <MetricLabel>{card.rank}</MetricLabel>
                </MetricLabelContainerUpSideDown>

                <MetricValue up={Math.sign(card?.difference) === 1}>
                  {card?.difference}
                </MetricValue>
              </MetricCard>
            ))}
          </CardList>
        </ProfileCard>
      </UserCardList>
    )}
  </>
  );
};

export default UserSeatAndCards;
