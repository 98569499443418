import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Container from "../components/layout/Container";
import HeadingWithLogo from "../components/typography/HeadingWithLogo";
import Button from "../components/buttons/Button";
import { FormGroup } from "../components/forms/FormGroup";
import { Label } from "../components/forms/Label";
import { Input } from "../components/forms/Input";
import { Form } from "../components/forms/Form";
import RelativeWrapper from "../components/layout/RelativeWrapper";
import globalContext from "../context/global/globalContext";
import contentContext from "../context/content/contentContext";
import authContext from "../context/auth/authContext";
import styled from "styled-components";
import userAvatar from "../assets/img/avatar.png";

const Dashboard = () => {
  const fileInputRef = useRef(null);
  const { getLocalizedString } = useContext(contentContext);
  const { userName, email, picture, id } = useContext(globalContext);
  const { updateUserProfile } = useContext(authContext);

  const [name, setName] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [editProfileErrors, setEditProfileErrors] = useState({
    name: null,
  });

  useEffect(() => {
    setName(userName);
  }, [userName]);

  useEffect(() => {
    setImgSrc(picture);
  }, [picture]);

  const handleNameValidation = () => {
    const regexp = /^\s*\S[\s\S]*$/;
    let isValidated = true;
    if (name.length === 0) {
      setEditProfileErrors({
        ...editProfileErrors,
        name: "Name is required",
      });
      isValidated = false;
    }
    if (!regexp.test(name)) {
      setEditProfileErrors({
        ...editProfileErrors,
        name: "Name should not contain only whitespace characters.",
      });
      isValidated = false;
    }
    if (name.length > 100) {
      setEditProfileErrors({
        ...editProfileErrors,
        name: "Name field has a limit of max 100 characters.",
      });
      isValidated = false;
    }
    return isValidated;
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleInputImageChange = (file) => {
    const reader = new FileReader();
    const { files } = file.target;
    if (files && files.length !== 0) {
      setSelectedImage(files[0]);
      reader.onload = () => setImgSrc(reader.result);
      reader.readAsDataURL(files[0]);
    }
  };

  const handleUpdateUserProfile = () => {
    if (!handleNameValidation()) {
      return;
    }
    updateUserProfile(selectedImage, name, id);
  };

  return (
    <RelativeWrapper>
      <Container
        fullHeight
        flexDirection="column"
        justifyContent="center"
        contentCenteredMobile
        alignItems="center"
        padding="6rem 2rem 2rem 2rem"
      >
        <Form onSubmit={(e) => e.preventDefault()}>
          <HeadingWithLogo textCentered hideIconOnMobile={false}>
            My Profile
          </HeadingWithLogo>
          <AvatarOuterContainer>
            <AvatarInnerContainer>
              <Image src={imgSrc || userAvatar} alt="User-Avatar" />
              <Button
                onClick={handleImageClick}
                primary
                htmlFor="account-settings-upload-image"
              >
                Upload New Photo
                <input
                  ref={fileInputRef}
                  hidden
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleInputImageChange}
                />
              </Button>
            </AvatarInnerContainer>
          </AvatarOuterContainer>
          <HorizontalLine />
          <FormGroup>
            <Label>Name</Label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setEditProfileErrors({
                  ...editProfileErrors,
                  name: null,
                });
              }}
              onBlur={handleNameValidation}
            />
            {editProfileErrors.name && (
              <ValidationError>{editProfileErrors.name}</ValidationError>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input
              style={{ cursor: "not-allowed" }}
              disabled
              type="email"
              value={email}
            />
          </FormGroup>
          <FormGroup onClick={handleUpdateUserProfile}>
            <Button primary>Save Changes</Button>
          </FormGroup>
          <HorizontalLine />
          <FormGroup style={{ marginBottom: "1rem" }}>
            <Button
              style={{ marginBottom: "1rem" }}
              as={Link}
              to="/logged-in-reset-password"
            >
              Change Password
            </Button>
            {/* <Button>
              {getLocalizedString("dashboard-delete_acct_btn_text")}
            </Button> */}
          </FormGroup>
          <Button
            as={Link}
            to="/"
            secondary
            style={{ gridColumnStart: "1", gridColumnEnd: "3" }}
          >
            {getLocalizedString("static_page-back_btn_txt")}
          </Button>
        </Form>
      </Container>
    </RelativeWrapper>
  );
};

const ValidationError = styled.p`
  text-align: left;
  font-size: 14px;
  margin-top: 5px;
  color: red;
`;

const HorizontalLine = styled.div`
  border: 1px dotted #707070;
  margin: 1rem;
  width: 100%;
`;

const AvatarOuterContainer = styled.div`
  display: flex;
  width: 100%;
`;

const AvatarInnerContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;
`;

const Image = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 1rem;
`;

export default Dashboard;
