import React, { useContext, useEffect, useState } from "react";
import Container from "../components/layout/Container";
import Button from "../components/buttons/Button";
import gameContext from "../context/game/gameContext";
import socketContext from "../context/websocket/socketContext";
import PokerTable from "../components/game/PokerTable";
import { RotateDevicePrompt } from "../components/game/RotateDevicePrompt";
import { PositionedUISlot } from "../components/game/PositionedUISlot";
import { PokerTableWrapper } from "../components/game/PokerTableWrapper";
import { Seat } from "../components/game/Seat";
import Text from "../components/typography/Text";
import modalContext from "../context/modal/modalContext";
import { withRouter } from "react-router-dom";
import { TableInfoWrapper } from "../components/game/TableInfoWrapper";
import { InfoPill } from "../components/game/InfoPill";
import { GameUI } from "../components/game/GameUI";
import { GameStateInfo } from "../components/game/GameStateInfo";
import contentContext from "../context/content/contentContext";
import HamburgerButton from "../components/buttons/HamburgerButton";
import GameSidebar from "../components/game/GameSidebar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import UserSeatAndCards from "../components/game/UserSeatAndCards";
import styled from "styled-components";

const NewPlay = ({ history }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [hidleMySeat, setHideMySeat] = useState(-1);
  const [countDownDisplay, setCountDownDisplay] = useState(5);
  const { socket } = useContext(socketContext);
  const { openModal } = useContext(modalContext);
  const {
    messages,
    currentTable,
    isPlayerSeated,
    seatId,
    joinTable,
    leaveTable,
    sitDown,
    standUp,
    fold,
    check,
    call,
    raise,
    revealCard,
    selectedCard,
    countDown,
  } = useContext(gameContext);
  const { getLocalizedString } = useContext(contentContext);
  const [bet, setBet] = useState(0);
  const location = useLocation();
  const tableId = location?.state?.tableId;

  useEffect(() => {
    if(countDown){
      const interval = setInterval(() => {
        const now = Date.now();
        const timeLeft = Math.max(0, Math.floor((countDown - now) / 1000));
        setCountDownDisplay(timeLeft);
    
        if (timeLeft <= 0) {
          clearInterval(interval);
        }
      }, 1000);
    
      return () => clearInterval(interval);
    }
  }, [countDown])

  useEffect(() => {
    !socket &&
      openModal(
        () => (
          <Text>{getLocalizedString("game_lost-connection-modal_text")}</Text>
        ),
        getLocalizedString("game_lost-connection-modal_header"),
        getLocalizedString("game_lost-connection-modal_btn-txt"),
        () => history.push("/")
      );
    socket && joinTable(tableId);
    return () => leaveTable();
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    currentTable &&
      (currentTable.callAmount > currentTable.minBet
        ? setBet(currentTable.callAmount)
        : currentTable.pot > 0
          ? setBet(currentTable.minRaise)
          : setBet(currentTable.minBet));

    updateMySeatDisplay();
  }, [currentTable]);

  const updateMySeatDisplay = () => {
    if (seatId) {
      const seatData = currentTable?.seats[seatId];
      if (seatData && seatData.hand && seatData.hand.length > 0) {
        setHideMySeat(seatId);
      } else {
        setHideMySeat(-1);
      }
    }
  }

  return (
    <div style={{ background: "black", position: "relative" }}>
      {showSidebar && (
        <GameSidebar
          onClose={() => setShowSidebar(false)}
          currentTable={currentTable}
        />
      )}

      <div>
        <RotateDevicePrompt />
        {!showSidebar && (
          <div
            style={{
              top: "1rem",
              left: "1rem",
              position: "absolute",
              zIndex: "99999",
            }}
          >
            <HamburgerButton
              clickHandler={() => {
                setShowSidebar(true);
              }}
            />
          </div>
        )}

        <Container fullHeight>
          {currentTable && (
            <>
              <PositionedUISlot
                bottom="2vh"
                left="1.5rem"
                scale="0.65"
                style={{ zIndex: "50" }}
              >
                <Button small secondary onClick={leaveTable}>
                  {getLocalizedString("game_leave-table-btn")}
                </Button>
              </PositionedUISlot>
              {!isPlayerSeated && (
                <PositionedUISlot
                  bottom="1.5vh"
                  right="1.5rem"
                  scale="0.65"
                  style={{ pointerEvents: "none", zIndex: "50" }}
                  origin="bottom right"
                >
                  <TableInfoWrapper>
                    <Text textAlign="right">
                      <strong>{currentTable.name}</strong> |{" "}
                      <strong>
                        {getLocalizedString("game_info_limit-lbl")}:{" "}
                      </strong>
                      {new Intl.NumberFormat(
                        document.documentElement.lang
                      ).format(currentTable.limit)}{" "}
                      |{" "}
                      <strong>
                        {getLocalizedString("game_info_blinds-lbl")}:{" "}
                      </strong>
                      {new Intl.NumberFormat(
                        document.documentElement.lang
                      ).format(currentTable.minBet)}{" "}
                      /{" "}
                      {new Intl.NumberFormat(
                        document.documentElement.lang
                      ).format(currentTable.minBet * 2)}
                    </Text>
                  </TableInfoWrapper>
                </PositionedUISlot>
              )}
            </>
          )}
          <PokerTableWrapper>
            <PokerTable />
            {currentTable && (
              <>
                {/* <PositionedUISlot
                  top="17%"
                  left="-60px"
                  scale="0.55"
                  origin="top left"
                >
                  <Seat
                    seatNumber={1}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  top="-2%"
                  left="10%"
                  scale="0.55"
                  origin="top center"
                >
                  <Seat
                    seatNumber={2}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  top="17%"
                  right="-3%"
                  scale="0.55"
                  origin="top right"
                >
                  <Seat
                    seatNumber={3}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  bottom="15%"
                  right="-3%"
                  scale="0.55"
                  origin="bottom right"
                >
                  <Seat
                    seatNumber={4}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  bottom="15%"
                  left="-60px"
                  scale="0.55"
                  origin="bottom left"
                >
                  <Seat
                    seatNumber={5}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot>
                <PositionedUISlot
                  top="-2%"
                  right="10%"
                  scale="0.55"
                  origin="top center right"
                >
                  <Seat
                    seatNumber={6}
                    currentTable={currentTable}
                    isPlayerSeated={isPlayerSeated}
                    sitDown={sitDown}
                  />
                </PositionedUISlot> */}
                {[1, 2, 3, 4, 5, 6].map((number) => (
                  (number !== hidleMySeat) && (
                    <PositionedUISlot
                      key={number}
                      top={number === 1 || number === 3 ? "17%" : number === 2 || number === 6 ? "-2%" : "bottom"}
                      left={number === 1 || number === 5 ? "-60px" : number === 2 ? "10%" : "right"}
                      right={number === 3 || number === 4 ? "-3%" : number === 6 ? "10%" : undefined}
                      bottom={number === 4 || number === 5 ? "15%" : undefined}
                      scale="0.55"
                      origin={
                        number === 1 ? "top left" :
                          number === 2 ? "top center" :
                            number === 3 ? "top right" :
                              number === 4 ? "bottom right" :
                                number === 5 ? "bottom left" :
                                  "top center right"
                      }
                    >
                      <Seat
                        seatNumber={number}
                        currentTable={currentTable}
                        isPlayerSeated={isPlayerSeated}
                        sitDown={sitDown}
                      />
                    </PositionedUISlot>
                  )
                ))}
                {/* <PositionedUISlot
                  width="100%"
                  origin="center center"
                  scale="0.60"
                  style={{
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {currentTable.board && currentTable.board.length > 0 && (
                    <>
                      {currentTable.board.map((card, index) => (
                        <PokerCard key={index} card={card} />
                      ))}
                    </>
                  )}
                </PositionedUISlot> */}
                <PositionedUISlot
                  // bottom="8%"
                  style={{ transform: "translate(0px, -55px)" }}
                  scale="0.60"
                  origin="bottom center"
                >
                  {messages && messages.length > 0 && (
                    <>
                      <InfoPill>
                        {messages[messages.length - 1] !== "---New hand starting in 5 seconds---" && messages[messages.length - 1]}
                        {messages[messages.length - 1] === "---New hand starting in 5 seconds---" && (
                          <span> {countDownDisplay}</span>
                        )}
                      </InfoPill>
                      {!isPlayerSeated && (
                        <InfoPill>Sit down to join the game!</InfoPill>
                      )}
                      {currentTable.winMessages.length > 0 && (
                        <InfoPill>
                          {
                            currentTable.winMessages[
                              currentTable.winMessages.length - 1
                            ]
                          }
                        </InfoPill>
                      )}
                    </>
                  )}
                </PositionedUISlot>
                <PositionedUISlot
                  // bottom="25%"
                  style={{ transform: "translate(5%, 20px)" }}
                  scale="0.60"
                  origin="center center"
                >
                  {currentTable.winMessages.length === 0 && (
                    <GameStateInfo currentTable={currentTable} />
                  )}
                </PositionedUISlot>
              </>
            )}
            <UserSeatAndCardsContainer>
              <UserSeatAndCards currentTable={currentTable} />
            </UserSeatAndCardsContainer>
          </PokerTableWrapper>

          {currentTable &&
            isPlayerSeated &&
            currentTable.seats[seatId] &&
            currentTable.seats[seatId].turn && (
              <GameUI
                currentTable={currentTable}
                seatId={seatId}
                bet={bet}
                setBet={setBet}
                raise={raise}
                standUp={standUp}
                fold={fold}
                check={check}
                call={call}
                revealCard={revealCard}
                selectedCard={selectedCard}
              />
            )}
        </Container>
      </div>
    </div>
  );
};

const UserSeatAndCardsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

export default withRouter(NewPlay);
