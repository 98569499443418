import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import CardLayout from "../../assets/img/card-layout.png";
import CardBackside from "../../assets/img/backside-card-cropped.png";
import InnerCardLogoBear from "../../assets/img/inner-card-logo-bear.png";
import InnerCardLogoOx from "../../assets/img/inner-card-logo-ox.png";
import gameContext from "../../context/game/gameContext";

const StyledPokerCardWrapper = styled.div`
  display: inline-block;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  transition: all 0.5s;
  width: 70px;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 1;
  left: ${(props) => props.index * 30}px;

  @keyframes fadeInUp {
    from {
      -webkit-transform: translate3d(0, 40px, 0);
      transform: translate3d(0, 40px, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeInUp {
    from {
      -webkit-transform: translate3d(0, 40px, 0);
      transform: translate3d(0, 40px, 0);
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`;

const MetricCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100px;
  height: 100px;
  background-image: url(${CardLayout});
  background-size: cover;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const BacksideCardContainer = styled.div`
  display: "block";
  width: 100%;
  max-width: 100px;
  height: 100px;
`;

const InnerLogo = styled.img`
  position: absolute;
  width: ${(props) => (props.src === InnerCardLogoBear ? "50px" : "50px")};
  top: ${(props) => (props.src === InnerCardLogoBear ? "50%" : "50%")};
  left: ${(props) => (props.src === InnerCardLogoBear ? "50%" : "50%")};
  transform: translate(-50%, -50%);
  height: ${(props) => (props.src === InnerCardLogoBear ? "50px" : "50px")};
`;

const MetricLabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 5px;
`;

const MetricLabelContainerUpSideDown = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 5px;
  transform: rotate(180deg);
`;

const MetricLabel = styled.div`
  font-size: 8px;
  font-weight: bold;
  margin-inline: 10px;
`;

const MetricValue = styled.span`
  font-size: 8px;
  font-weight: bold;
  color: ${(props) => (props.up ? "green" : "red")};
  position: absolute;
  bottom: 18px;
`;

const TooltipCardWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 125px;
  height: 180px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  background-color: white;
  display: ${(props) => (props.showTooltip ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  ${(props) =>
    props.position === "top"
      ? "bottom: 100%; left: 50%; transform: translateX(-50%);"
      : props.position === "bottom"
      ? "top: 100%; left: 50%; transform: translateX(-50%);"
      : props.position === "left"
      ? "right: 100%; top: 50%; transform: translateY(-50%);"
      : "left: 100%; top: 50%; transform: translateY(-50%);"};
`;

const PokerCard = ({ card, index }) => {
  const { setCard } = useContext(gameContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState("top");
  const cardRef = useRef(null);

  useEffect(() => {
    if (showTooltip) {
      const handlePosition = () => {
        const cardRect = cardRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        if (cardRect.top > 220) {
          setTooltipPosition("top");
        } else if (windowHeight - cardRect.bottom > 220) {
          setTooltipPosition("bottom");
        } else if (cardRect.left > 220) {
          setTooltipPosition("left");
        } else if (windowWidth - cardRect.right > 220) {
          setTooltipPosition("right");
        } else {
          setTooltipPosition("bottom");
        }
      };

      handlePosition();
      window.addEventListener("resize", handlePosition);
      return () => window.removeEventListener("resize", handlePosition);
    }
  }, [showTooltip]);

  // const handleCardSelection = (cardSymbol) => {
  //   setCard(cardSymbol);
  // };

  return (
    <StyledPokerCardWrapper
      ref={cardRef}
      index={index}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      showTooltip={showTooltip}
      // onClick={() => handleCardSelection(card.symbol)}
    >
      {card?.display ? (
        <>
          <MetricCard
            key={card?.difference}
            up={Math.sign(card?.difference) === 1}
          >
            <MetricLabelContainer>
              <MetricLabel>{card?.symbol}</MetricLabel>
              <MetricLabel>{card?.rank}</MetricLabel>
            </MetricLabelContainer>
            <InnerLogo
              src={
                Math.sign(card?.difference) === 1
                  ? InnerCardLogoOx
                  : InnerCardLogoBear
              }
            />
            <MetricLabelContainerUpSideDown>
              <MetricLabel>{card?.symbol}</MetricLabel>
              <MetricLabel>{card?.rank}</MetricLabel>
            </MetricLabelContainerUpSideDown>

            <MetricValue up={Math.sign(card?.difference) === 1}>
              {card?.difference}
            </MetricValue>
          </MetricCard>

          <TooltipCardWrapper
            showTooltip={showTooltip}
            position={tooltipPosition}
          >
            <MetricCard
              style={{ maxWidth: "125px", height: "180px" }}
              key={card?.difference}
              up={Math.sign(card?.difference) === 1}
            >
              <InnerLogo
                style={{ width: "100px", height: "100px" }}
                src={
                  Math.sign(card?.difference) === 1
                    ? InnerCardLogoOx
                    : InnerCardLogoBear
                }
              />
              <MetricLabelContainer>
                <MetricLabel style={{ fontSize: "14px" }}>
                  {card?.symbol}
                </MetricLabel>
                <MetricLabel style={{ fontSize: "14px" }}>
                  {card?.rank}
                </MetricLabel>
              </MetricLabelContainer>

              <MetricLabelContainerUpSideDown>
                <MetricLabel style={{ fontSize: "14px" }}>
                  {card?.symbol}
                </MetricLabel>
                <MetricLabel style={{ fontSize: "14px" }}>
                  {card?.rank}
                </MetricLabel>
              </MetricLabelContainerUpSideDown>

              <MetricValue
                style={{ fontSize: "14px" }}
                up={Math.sign(card?.difference) === 1}
              >
                {card?.difference}
              </MetricValue>
            </MetricCard>
          </TooltipCardWrapper>
        </>
      ) : (
        <BacksideCardContainer>
          <img
            style={{ height: "98px", width: "70px" }}
            src={CardBackside}
            alt="Backside-of-card"
          />
        </BacksideCardContainer>
      )}
    </StyledPokerCardWrapper>
  );
};

export default PokerCard;
