import axiosInstance from '../axiosConfig';

export const fetchTables = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_TABLES_REQUEST'
        });
        return axiosInstance.get('/table/all')
            .then(response => {
                const tables = response.data;
                console.log("🚀 ~ return ~ tables:", tables)
                dispatch({
                    type: 'FETCH_TABLES_SUCCESS',
                    payload: tables
                });
                return tables;
            })
            .catch(error => {
                const errorMsg = error.message;
                dispatch({
                    type: 'FETCH_TABLES_FAILURE',
                    payload: errorMsg,
                });
                throw error;
            });
    };
};