import React from "react";
import styled from "styled-components";
import table from "../../assets/img/new-table.png";

const StyledPokerTable = styled.img`
  display: block;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
`;

const PokerTable = () => <StyledPokerTable src={table} alt="Poker Table" />;

export default PokerTable;
