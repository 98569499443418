const theme = {
  // Colors
  colors: {
    // Primary Brand Colors
    primaryCta: "hsl(0, 100%, 46%)", // Red color for buttons like "Shop"
    primaryCtaDarker: "hsl(0, 100%, 36%)", // Darker red for hover states
    secondaryCta: "hsl(0, 0%, 20%)", // Dark gray for secondary buttons
    secondaryCtaDarker: "hsl(0, 0%, 15%)", // Darker gray for hover states
    secondaryCtaDarkest: "hsl(0, 0%, 10%)", // Darkest gray for active states
    lightGrayColor: "hsl(0, 0%, 40%)", // Light gray for text like "Frame 100..."

    // Background Colors
    darkBg: "hsl(0, 0%, 10%)", // Very dark gray, almost black for main background
    lightBg: "hsl(0, 0%, 15%)", // Slightly lighter dark gray for card backgrounds
    lightestBg: "hsl(0, 0%, 20%)", // Even lighter gray for hover states or highlights

    // Font Colors
    fontColorLight: "hsl(0, 0%, 100%)", // White for most text
    fontColorDark: "hsl(0, 0%, 0%)", // Black for contrast when needed
    fontColorDarkLighter: "hsl(0, 0%, 70%)", // Light gray for secondary text

    // Other colors
    accentColor: "hsl(0, 100%, 46%)", // Red accent color (same as primaryCta)
    goldenColor: "hsl(35, 90%, 50%)", // Updated gold color for the coin icon
    dangerColor: "hsl(0, 100%, 46%)", // Red color (same as primaryCta) for warnings or errors
    inputBackgroundColor: "hsl(0, 0%, 15%)", // Dark gray for input fields

    // Updated colors
    playingCardBg: "hsl(0, 0%, 15%)", // Dark gray for card backgrounds
    playingCardBgLighter: "hsl(0, 0%, 20%)", // Slightly lighter gray for card highlights
    dangerColorLighter: "hsl(0, 100%, 56%)", // Kept as is, but you might want to adjust
  },
  // Fonts
  fonts: {
    // Font Familys
    fontFamilySerif: "'Playfair Display', serif",
    fontFamilySansSerif: "'Roboto', sans-serif",
    // Font Sizes
    fontLineHeight: "1.4",
    fontSizeRoot: "1em",
    fontSizeRootMobile: "0.9em",
    fontSizeH1: "calc(1.25rem + 4vmin)",
    fontSizeH2: "calc(1.25rem + 3.5vmin)",
    fontSizeH3: "calc(1.25rem + 3vmin)",
    fontSizeH4: "calc(1.25rem + 2vmin)",
    fontSizeH5: "calc(1.25rem + 1.5vmin)",
    fontSizeH6: "calc(1.25rem + 1vmin)",
    fontSizeParagraph: "1.2rem",
  },
  // Other styles
  other: {
    // Border-radius
    stdBorderRadius: "2rem",
    standardBorderRadius: "5px",
    // Drop Shadows
    cardDropShadow: "10px 10px 30px rgba(0, 0, 0, 0.1)",
    navMenuDropShadow: "-10px 0px 30px rgba(0, 0, 0, 0.1)",
  },
};

export default theme;
