import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import innerTableImage from "../assets/img/inner-table.png";
import { useSelector, useDispatch } from 'react-redux';
import { fetchTables } from '../actions/tablesActions';
import globalContext from "../context/global/globalContext";

const JoinTables = ({ history }) => {
  // const dispatch = useDispatch();
  // const [tablesData, setTablesData] = useState([]);
  // useEffect(async () => {
  //   try{
  //     const response = await dispatch(fetchTables());
  //     setTablesData(response.tables);
  //   }
  //   catch(err){
  //     console.log("🚀 ~ useEffect ~ err:", err);
  //   }
  // }, [dispatch]); 
  const { tables } = useContext(globalContext);
  const redirectToPlay = (tableId) => {
    history.push(`/play`, { tableId });
  }
  return (
    <StyledJoinTables>
      <h1>Pick A Room</h1>
      <TableGrid>
        {tables?.map((table) => (
          <TableCard key={table._id} onClick={() => redirectToPlay(table.id)}>
            <h2>Table # {table.name}</h2>
            <TableImage>
              <PriceRange>{`$${table.smallBlind} - $${table.bigBlind}`}</PriceRange>
            </TableImage>
          </TableCard>
        ))}
      </TableGrid>
    </StyledJoinTables>
  );
};

const StyledJoinTables = styled.div`
  text-align: center;
  color: white;
  background-color: black;
  min-height: 100vh;
  padding: 4rem 0; // Increased padding top and bottom

  h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem; // Increased margin below the title
  }
`;

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const TableCard = styled.div`
  background-color: black;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  h2 {
    margin-bottom: 0.5rem;
  }
`;

const TableImage = styled.div`
  background-image: url(${innerTableImage});
  background-size: contain;
  background-position: center;
  width: 95%;
  height: 0;
  padding-bottom: 56.25%; // This creates a 16:9 aspect ratio
  position: relative;
`;

const PriceRange = styled.div`
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "YAFcfha3TqI 0", _fb_, auto;
`;

export default JoinTables;
