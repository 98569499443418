import React, { useContext, useEffect } from 'react';
import Landing from './Landing';
import MainPage from './MainPage';
import authContext from '../context/auth/authContext';
import setAuthToken from '../helpers/setAuthToken';

const HomePage = () => {
  const { isLoggedIn, loadUser } = useContext(authContext);
  
  // Get token from URL parameters
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      setAuthToken(token);
      loadUser(token);
    }
  }, [token]);

  if (!isLoggedIn) return <Landing />;
  else return <MainPage />;
};

export default HomePage;
